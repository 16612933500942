import React, { useEffect, useState } from 'react';

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div
        style={{
          backgroundColor: 'red',
          border: '1px solid #ffffff',
          position: 'absolute',
          top: 20,
          left: 10,
          right: 10,
          padding: 10,
          verticalAlign: 'middle',
          color: '#ffffff',
          borderRadius:5
        }}
      >
        <h1 style={{ fontSize: 14, textAlign: 'center', marginBottom: 0,fontWeight:'bold' }}>
          No Internet Connection. Please try again later.
        </h1>
      </div>
    );
  }
};

export default NoInternetConnection;
