import { LDProvider, useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import settings from '../../constants/constants';
import { ffDefaults } from '../../data/ffDefaults';
import React, { useEffect, useMemo, useState } from 'react';
import { FeatureFlagSet } from './types';
import { getUser } from '../../services/authentication.service';
import { Logger } from '../logger';

const launchDarklyDefaults = {
  reactOptions: {
    useCamelCaseFlagKeys: false,
  }
};
const launchDarklyConfig = settings.launchDarklyConfig;
const ffOverrides: FeatureFlagSet = settings.ffOverrides ?? {};

const logger = new Logger('featureFlags');
const sources = {
  /**
   * Connects to Launch Darkly as a source for feature flags.
   * Any configured overrides will still apply.
   */
  launchDarkly: {
    FeatureFlagProvider: (props: { children: JSX.Element }) => {
      const user = getUser();
      const hasIdentity = !!(user?.user);
      const key = hasIdentity ? user.user.id : null;
      const name = hasIdentity ? `${user.user.first_name} ${user.user.last_name}` : null;
      const email = hasIdentity ? user.user.email : null;

      const context = useMemo(() => (
        hasIdentity ? {
          kind: 'user',
          key: key,
          name: name,
          email: email,
        } : null
      ), [hasIdentity, key, name, email]);

      return (
        <LDProvider
          {...launchDarklyDefaults}
          {...launchDarklyConfig}
          context={context}
        >{props.children}</LDProvider>
      );
    },
    useFeatureFlags: () => {
      const flags = useFlags();
      const finalFlags: FeatureFlagSet = useMemo(() => ({
        ...ffDefaults,
        ...flags,
        ...ffOverrides,
      }), [flags]);

      return finalFlags;
    },
    useFeatureFlagsReady: () => {
      const client = useLDClient();
      const [ready, setReady] = useState(false);

      useEffect(() => {
        client?.waitForInitialization()
          .then(() => {
            logger.info('LaunchDarkly initialised');
          })
          .catch((e) => {
            logger.error('LaunchDarkly failed to initialise', e);
          })
          .finally(() => {
            setReady(true);
          });
      }, [client]);

      return ready;
    },
  },
  /**
   * Operates on the feature flag defaults and overrides.
   * Does not make any external connections.
   */
  static: {
    FeatureFlagProvider: (props: { children: JSX.Element }) => props.children,
    useFeatureFlags: (): FeatureFlagSet => {
      const finalFlags = useMemo(() => ({
        ...ffDefaults,
        ...ffOverrides,
      }), []);

      return finalFlags;
    },
    useFeatureFlagsReady: () => true,
  },
};

const {
  FeatureFlagProvider,
  useFeatureFlags,
  useFeatureFlagsReady,
} = launchDarklyConfig ? sources.launchDarkly : sources.static;

export {
  FeatureFlagProvider,
  useFeatureFlags,
  useFeatureFlagsReady,
};
