//! DO NOT EDIT THIS FILE IT IS GENERATED USING:
// yarn generate-flags

// Default On Variation: true
// Default Off Variation: false
export const FF_SUPPLIER_SHOPIFY_ORDERS = 'supplier_shopify_orders';

// Default On Variation: 5
// Default Off Variation: 0
export const FF_APHRA_MAX_UPLOAD_SIZE_MB = 'APHRA_MAX_UPLOAD_SIZE_MB';

// Default On Variation: 5
// Default Off Variation: 0
export const FF_APPROVAL_MAX_FILE_SIZE_MB = 'APPROVAL_MAX_FILE_SIZE_MB';

// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACIST_NEW_EXPERIENCE_CHECKOUT = 'pharmacist_new_experience_checkout';

// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACIST_NEW_EXPERIENCE_ORDERS = 'pharmacist_new_experience_orders';

// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACIST_NEW_EXPERIENCE = 'pharmacist_new_experience';

// Default On Variation: true
// Default Off Variation: false
export const FF_SHIPMENTS_EXPORT = 'shipments_export';

// Default On Variation: true
// Default Off Variation: false
export const FF_USE_CART_LOADER = 'use_cart_loader';

// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACIST_ARCHIVE_MODE = 'pharmacist_archive_mode';

// Default On Variation: 5
// Default Off Variation: 0
export const FF_BULK_CART_MAX_UPLOAD_SIZE_MB = 'BULK_CART_MAX_UPLOAD_SIZE_MB';

// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACY_REGISTRATION = 'leafio_classic_registration';
