import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { FeatureFlagProvider } from './utils/featureFlags';
import * as serviceWorker from './serviceWorker';
require('./components/common/APM');

axios.defaults.headers.post['Content-Type'] = 'application/json';

const root = createRoot(document.getElementById('root'));

root.render(
  <FeatureFlagProvider>
    <App />
  </FeatureFlagProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
