import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import NoInternetConnection from './components/common/NoInternetConnection';
import AppRoutes from './routes/routes';

function App() {
  return (
    <>
      <NoInternetConnection>
        <ToastContainer
          position='top-right'
          autoClose={7500}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          pauseOnHover
        />
        <AppRoutes />
      </NoInternetConnection>
    </>
  );
}

export default App;
