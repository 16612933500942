import { toast } from 'react-toastify';

export default function toasterNotification(message, type, customOptions = {}) {
  const options = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    ...(customOptions.id && { toastId: customOptions.id }),
  };
  switch(type) {
  case 'success':
    toast.success(message, options);
    break;
  case 'warning':
    toast.warn(message, options);
    break;
  case 'error':
    toast.error(message, options);
    break;
  case 'info':
    toast.info(message, options);
    break;
  }
}
