/* eslint-disable @typescript-eslint/no-explicit-any */
import debug, { Debugger } from 'debug';

/*
  Usage:-
    logs will show in development only, in production logs will be disabled
    to turn on in production set localStorage.debug = 'MG*';

  import { Logger } from 'utils/logger';
  const logger = new Logger('MyComponent');
  ...
  ...
  logger.info(`hello world ${someValue}`);

*/



const NAMESPACE = 'MG';

if (process.env.REACT_APP_APP_ENV === 'development') {
  debug.enable(`${NAMESPACE}*`);
}

export class Logger {
  _debug: Debugger;
  _info: Debugger;
  _warn: Debugger;
  _error: Debugger;
  prefix?: string;

  constructor(prefix?) {
    if (prefix) {
      this._debug = debug(`${NAMESPACE}:${prefix}`);
      this._info = debug(`${NAMESPACE}:INFO:${prefix}`);
      this._warn = debug(`${NAMESPACE}:WARN:${prefix}`);
      this._error = debug(`${NAMESPACE}:ERROR:${prefix}`);
    } else {
      this._debug = debug(`${NAMESPACE}`);
      this._info = debug(`${NAMESPACE}:INFO`);
      this._warn = debug(`${NAMESPACE}:WARN`);
      this._error = debug(`${NAMESPACE}:ERROR`);
    }

    this.prefix = prefix;

    this._debug.log = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params, no-console
      console.debug.apply(console, arguments as any);
    };
    this._info.log = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params, no-console
      console.info.apply(console, arguments as any);
    };
    this._warn.log = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params, no-console
      console.warn.apply(console, arguments as any);
    };
    this._error.log = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params, no-console
      console.error.apply(console, arguments as any);
    };
  }

  debug(...args) {
    this.wrapLog(this._debug, 'debug', ...args);
  }

  info(...args) {
    this.wrapLog(this._info, 'info', ...args);
  }

  warn(...args) {
    this.wrapLog(this._warn, 'warn', ...args);
  }

  error(...args) {
    this.wrapLog(this._error, 'error', ...args);
  }

  wrapLog = (logFunc, logLevel, ...args) => {
    logFunc(...args);

    /*     const text: string | undefined = args
          .map((value) => (typeof value === 'object' ? JSONStringify(value) : value))
          .join(', ');

          logLevel != "debug" &&
          LOGSENDER &&
          LOGSENDER.push({
            message: text,
            prefix: this.prefix,
            level: logLevel,
          });
     */
  };
}

/* function JSONStringify(object: any) {
  const masks = [];

  // eslint-disable-next-line no-undefined
  const replacer = (k, v) => (masks.includes(k) ? undefined : v);
  try {
    return JSON.stringify(object, replacer);
  } catch (err) {
    return '[failed to JSON.Stringify Object]';
  }
}
 */
