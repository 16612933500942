import React, { FC, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircleLoader from '../components/common/circleLoader';
import settings from '../constants/constants';
import {
  FF_PHARMACIST_ARCHIVE_MODE,
  FF_PHARMACIST_NEW_EXPERIENCE,
  FF_PHARMACIST_NEW_EXPERIENCE_CHECKOUT,
  FF_PHARMACIST_NEW_EXPERIENCE_ORDERS
} from '../constants/featureFlags';
import { getUser, isLoggedIn, logout, getLoggedInUserDetails } from '../services/authentication.service';
import PharmacyArchiveModeAlert from '../components/common/pharmacyArchiveModeAlert';
import { useFeatureFlags, useFeatureFlagsReady } from '../utils/featureFlags';

//  Old pages
const SignUpForm = lazy(() => import('../components/auth/signupForm'));
const SignInForm = lazy(() => import('../components/auth/signInForm'));
const ForgotPassword = lazy(() => import('../components/auth/forgotPassword'));
const ResetPassword = lazy(() => import('../components/auth/resetPassword'));
const SignOut = lazy(() => import('../components/auth/signout'));
const TermsAndPolicy = lazy(() => import('../components/auth/termsAndPolicy'));
const VerifyUserByAdmin = lazy(() => import('../components/auth/verifyUserByAdmin'));
const ProductCatalog = lazy(() => import('../pages/products/productCatalog'));
const ProductOffering = lazy(() => import('../pages/products/productOffering'));
const Order = lazy(() => import('../pages/orders/order'));
const OrderDetail = lazy(() => import('../pages/orders/orderDetail'));
const ViewCart = lazy(() => import('../pages/cart/viewCart'));
const HealthCheck = lazy(() => import('./healthCheck'));
const Inventory = lazy(() => import('../components/inventory/product'));
const HeaderUnauth = lazy(() => import('../components/layout/header-unauth'));
const OrderSuccess = lazy(() => import('../pages/orders/orderSuccess'));
const ConsignmentDetail = lazy(() => import('../pages/orders/consignmentDetail'));
const AuthLayout = lazy(() => import('../components/layout/Authlayout'));
const PharmacyUsers = lazy(() => import('../components/users/users'));
const Products = lazy(() => import('../pages/s8form/product'));
const Success = lazy(() => import('../pages/s8form/success'));
const Suppliers = lazy(() => import('../pages/suppliers/supplierUsers'));
const CartLoader = lazy(() => import('../pages/cartLoader/cartLoader'));
const AdminDashboard = lazy(() => import('../pages/admin/dashboard'));
const BranchRouting = lazy(() => import('../pages/branchRouting'));
const Reports = lazy(() => import('../pages/reports/reports'));
const S8Sync = lazy(() => import('../pages/s8Sync'));

// New pages
const ProductCatalogV2 = lazy(() => import('../v2/pages/productCatalog/productCatalog'));
const Orders = lazy(() => import('../v2/pages/orders/orders'));
const OrderDetailV2 = lazy(() => import('../v2/pages/orders/orderDetail'));
const Checkout = lazy(() => import('../v2/pages/checkout/checkout'));
const ConsignmentDetailV2 = lazy(() => import('../v2/pages/orders/consignmentDetail'));
const OrderSuccessV2 = lazy(() => import('../v2/pages/orders/orderSuccess'));

//  Shopify
const ShopifyOrders = lazy(() => import('../shopify/orders'));

//Reports
const SupplierReports = lazy(() => import('../pages/supplierReports'));

const allRoles = [
  settings.role.admin,
  settings.role.pharmacist,
  settings.role.supplier
];
const pharmacistOnly = [settings.role.pharmacist];
const supplierOnly = [settings.role.supplier];
const adminOnly = [settings.role.admin];

function MarketingSiteRedirect() {
  return (
    <div>
      <meta httpEquiv='refresh' content="0; url='https://info.leafio.com.au'" />
    </div>
  );
}

const AppRoutes = () => {
  const flags = useFeatureFlags() as FeatureFlagsType;
  const flagsReady = useFeatureFlagsReady();

  if (!flagsReady) {
    return <CircleLoader />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<CircleLoader/>}>
        <Routes>
          <Route path='/login' element={<SignInForm />}>
            <Route path=':loginType' element={<SignInForm />} />
          </Route>
          <Route path='/feedback' element={<Products />}/>
          <Route path='/feedback/success/:isdispute' element={<Success />}/>
          <Route path='/register' element={<SignUpForm flags={flags} />}/>
          <Route path='/forgot-password' element={<ForgotPassword />}>
            <Route path=':loginType' element={<ForgotPassword />}/>
          </Route>
          <Route path='/reset-password/:email' element={<ResetPassword />}/>
          <Route path='/health-check' element={<HealthCheck />}/>
          <Route path='/policy' element={<TermsAndPolicy />}/>
          <Route path='/verify-pharmacist/:id' element={<VerifyUserByAdmin />}/>
          <Route path='/products' element={<AuthenticatedRoute element={ProductCatalog} permittedRoles={allRoles} featureFlags={flags} restrictArchiveMode />}/>
          <Route path='/products/:id' element={<AuthenticatedRoute element={ProductOffering} permittedRoles={allRoles} restrictArchiveMode />}/>
          <Route path='/logout' element={<AuthenticatedRoute element={SignOut} permittedRoles={allRoles} />}/>
          <Route path='/orders' element={<AuthenticatedRoute element={Order} permittedRoles={allRoles} featureFlags={flags} />}/>
          <Route path='/orders/back-orders' element={<AuthenticatedRoute element={Order} permittedRoles={allRoles} featureFlags={flags} />}/>
          <Route path='/orders/consignments' element={<AuthenticatedRoute element={Order} permittedRoles={allRoles} featureFlags={flags} />}/>
          <Route path='/order-success' element={<AuthenticatedRoute element={OrderSuccess} permittedRoles={pharmacistOnly} featureFlags={flags} />}/>
          <Route path='/orders/:id' element={<AuthenticatedRoute element={OrderDetail} permittedRoles={allRoles} featureFlags={flags} />}/>
          <Route path='/consignments/:id/:from?' element={<AuthenticatedRoute element={ConsignmentDetail} permittedRoles={allRoles} featureFlags={flags} />}/>
          <Route path='/cart' element={<AuthenticatedRoute element={ViewCart} permittedRoles={pharmacistOnly} featureFlags={flags} restrictArchiveMode />}/>
          <Route path='/inventory' element={<AuthenticatedRoute element={Inventory} permittedRoles={adminOnly} />}/>
          <Route path='/users' element={<AuthenticatedRoute element={PharmacyUsers} permittedRoles={adminOnly} />}/>
          <Route path='/users/approvals' element={<AuthenticatedRoute element={PharmacyUsers} permittedRoles={adminOnly} />}/>
          <Route path='/users/consignments' element={<AuthenticatedRoute element={PharmacyUsers} permittedRoles={adminOnly} />}/>
          <Route path='/suppliers' element={<AuthenticatedRoute element={Suppliers} permittedRoles={adminOnly} />}/>
          <Route path='/cart-loader' element={<AuthenticatedRoute element={CartLoader} permittedRoles={adminOnly} />}/>
          <Route path='/irk90bvvw3' element={<AuthenticatedRoute element={AdminDashboard} permittedRoles={adminOnly} />}/>
          <Route path='/branch-routing' element={<AuthenticatedRoute element={BranchRouting} permittedRoles={adminOnly} />}/>
          <Route path='/reports' element={<AuthenticatedRoute element={Reports} permittedRoles={adminOnly} />}/>
          <Route path='/s8-sync' element={<AuthenticatedRoute element={S8Sync} permittedRoles={adminOnly} />}/>
          <Route path='/shopify-orders' element={<AuthenticatedRoute element={ShopifyOrders} permittedRoles={supplierOnly} />}/>
          <Route path='/supplier-reports' element={<AuthenticatedRoute element={SupplierReports} permittedRoles={supplierOnly} />}/>
          <Route path='/' element={<MarketingSiteRedirect />} />
          <Route path='/*' element={<NoMatch />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const NoMatch = () => {
  return <div style={{ 'height': '100vh' }}>
    <HeaderUnauth/>
    <div className='card text-center shadow py-4 center-box' style={{ maxWidth: '450px' }}>
      <div className='card-body'>
        <h2 className='card-title'>404</h2>
        <p className='card-text'>This page was not found. You may have mistyped the address or the page may have
          moved.</p>
        <Link className='btn btn-primary' to='/'>Go to homepage</Link>
        <p className='mt-4'>If you are still facing issues, please contact our support: <a
          href='mailto:support@leafio.com'>support@leafio.com</a></p>
      </div>
    </div>
  </div>;
};

type FeatureFlagsType = {
  [FF_PHARMACIST_NEW_EXPERIENCE]: boolean
  [FF_PHARMACIST_NEW_EXPERIENCE_CHECKOUT]: boolean
  [FF_PHARMACIST_NEW_EXPERIENCE_ORDERS]: boolean
}

type AuthenticatedRouteProps = {
  element: FC,
  permittedRoles: string[]
  featureFlags?: FeatureFlagsType
  restrictArchiveMode?: boolean;
}

const AuthenticatedRoute = (props : AuthenticatedRouteProps) => {
  useEffect(() => {
    checkIfUserIsLoggedIn();
  }, []);

  const checkIfUserIsLoggedIn = async () => {
    try {
      const user = await getLoggedInUserDetails();
      if (!user) await logout(true);
    } catch (error) {
      await logout(true);
    }
  };

  // Validate the user's session is still valid
  if (!isLoggedIn()) {
    toast('Please Login to continue', { toastId: 'login-first', type: 'warning' });
    return <Navigate to='/login/pharmacist'/>;
  }

  const Component = props.element;
  const permittedRoles = props.permittedRoles;
  const featureFlags = props.featureFlags;
  const restrictArchiveMode = props.restrictArchiveMode;

  let newPharmacistExperience = false;
  let archiveMode = false;

  // Validate user has correct role for the route
  const { user } = getUser();
  if (user && !permittedRoles.includes(user.role_id)) {
    return <Navigate to='/*' />;
  }

  if (user) {
    const user_role = user.role_id;
    newPharmacistExperience = user_role && user_role === settings.role.pharmacist && featureFlags && featureFlags[FF_PHARMACIST_NEW_EXPERIENCE];
    archiveMode = featureFlags?.[FF_PHARMACIST_ARCHIVE_MODE] && user_role && user_role === settings.role.pharmacist && !!(user.pharmacist?.archiveMode);
  }

  if (restrictArchiveMode && archiveMode) {
    toast(<PharmacyArchiveModeAlert />, { toastId: 'pharmacist-archive-mode', type: 'warning' });
    return <Navigate to='/orders' />;
  }

  // Redirect the pharmacist to the new experience
  if (newPharmacistExperience) {
    if (Component === ProductCatalog && featureFlags[FF_PHARMACIST_NEW_EXPERIENCE]) return <ProductCatalogV2/>;
    if (Component === ViewCart && featureFlags[FF_PHARMACIST_NEW_EXPERIENCE_CHECKOUT]) return <Checkout/>;
    if (Component === Order && featureFlags[FF_PHARMACIST_NEW_EXPERIENCE_ORDERS]) return <Orders/>;
    if (Component === OrderDetail && featureFlags[FF_PHARMACIST_NEW_EXPERIENCE_ORDERS]) return <OrderDetailV2/>;
    if (Component === OrderSuccess && featureFlags[FF_PHARMACIST_NEW_EXPERIENCE_ORDERS]) return <OrderSuccessV2/>;
    if (Component === ConsignmentDetail && featureFlags[FF_PHARMACIST_NEW_EXPERIENCE_ORDERS]) return <ConsignmentDetailV2/>;
  }

  // If the user is not a pharmacist and the new experience is not enabled, render the old components
  return <AuthLayout cssClassName={'main-content justify-content-center'} mainContent={<Component />}/>;
};

export default AppRoutes;
