export const CONCESSION_VENDORS = ['greena'];
export const CONCESSION_CONFIRMATION_LABEL = 'I confirm that I will sight and confirm that all patients for this product have an eligible concession card before dispensing';

/* eslint-disable no-undef */
const orderItemStatuses = {
  AWAITING_APPROVAL: 1,
  AWAITING_SHIPMENT: 2,
  SHIPPED: 3,
  COMPLETED: 4,
  CANCELLED: 5,
  APPROVAL_REJECTED: 6,
  APPROVAL_EXPIRED: 7,
  SHIPPED_WITH_DISPUTE: 8,
  WAREHOUSE_SHIPMENT: 9,
  PENDING_APPROVAL: 10,
  AWAITING_APPROVAL_EXCEEDED_ORDER_LIMIT: 11,
  PROCESSING_IN_WAREHOUSE: 12,
  DELETED: 13,
  SUPPLIER_AWAITING_APPROVAL: 14,
  REJECTED_BY_SUPPLIER: 15
};

const common = {
  'screenResolution': {
    small: 576,
    medium: 720,
    large: 960,
    extralarge: 1140,
    mobileMax: 767
  },
  GoogleAuthId: '413997005731-i04g60v2a66seejif5om8rp97v6ig1ui.apps.googleusercontent.com',
  role: {
    admin: 1,
    pharmacist: 2,
    supplier: 3,
  },
  loginTypes: ['admin', 'pharmacist', 'supplier'],
  approvalType: {
    SAS: 1,
    AP: 2
  },
  approvalStatuses: {
    Approve: 1,
    Reject: 2
  },
  approvalUpdated: 3,
  apDocDefaultMaxSizeMB: 5,
  aphraDefaultMaxSizeMB: 25,
  cartCsvDefaultMaxSizeMB: 2,
  productDocDefaultMaxSizeMB: 5,
  orderApprovalStatuses: {
    AWAITING_APPROVAL: 1,
    APPROVED: 2,
    REJECTED: 3,
    EXPIRED: 4
  },
  orderItemStatus: orderItemStatuses,
  backOrderStatus: {
    OUT_OF_STOCK: 1,
    IN_STOCK: 2,
    MOVED_AS_ORDER: 3,
    EXPIRED: 4,
    DELETED: 5
  },
  approvalAllowed: [orderItemStatuses.AWAITING_APPROVAL, orderItemStatuses.AWAITING_APPROVAL_EXCEEDED_ORDER_LIMIT],
  pagination: {
    limit: 20
  },
  prefixLabels: {
    ORDER: 'LFOD',
    PHARMACY: 'LFPD',
    CONSIGNMENT: 'LFCD',
    PHARMACISTID: 'LFPD',
    SUPPLIER: 'LFSD'
  },
  docsType: {
    ahpra: 1,
    apdoc: 2,
    digitalSign: 3,
    qrCode: 4,
    consignmentPdf: 5,
    productDoc: 6,
  },
  supportInfo: {
    number: '03 7003 8214'
  },
  shipmentTrackingURL: 'https://auspost.com.au/mypost/track/#/details/',
  myTeamGETrackingUrl: 'https://www.myteamge.com/?externalSearchQuery=',
  supplierOrderStatusData: [
    { label: 'Awaiting Supplier Approval', value: orderItemStatuses.SUPPLIER_AWAITING_APPROVAL },
    {
      label: 'Approved', value: [
        orderItemStatuses.SHIPPED,
        orderItemStatuses.AWAITING_SHIPMENT,
        orderItemStatuses.COMPLETED,
        orderItemStatuses.APPROVAL_REJECTED,
        orderItemStatuses.APPROVAL_EXPIRED,
        orderItemStatuses.SHIPPED_WITH_DISPUTE,
        orderItemStatuses.WAREHOUSE_SHIPMENT,
        orderItemStatuses.AWAITING_APPROVAL_EXCEEDED_ORDER_LIMIT,
        orderItemStatuses.PROCESSING_IN_WAREHOUSE,
      ]
    },
    { label: 'Rejected by Supplier', value: orderItemStatuses.REJECTED_BY_SUPPLIER },
  ],
  unauthorized: 401,
};

const local = {
  url: 'http://localhost:10000/api/v1',
  warehousingUrl: 'https://api.staging.montu.com.au/warehousing',
  shopifyStorefrontUrl: 'https://shop.leafio-stage.net/collections/all',
  launchDarklyConfig: {
    clientSideID: '64867d3d6df95c133661f58e',
  },
  shopifyRegistration: 'https://shop.leafio-stage.net/pages/registration',
  ffOverrides: {
    /**
     * Flags set here will override any flags received by any other provider
     * Useful for local development.
     */
  },
  ...common
};

const staging = {
  url: 'https://www.staging.leafio.com.au/api/v1',
  warehousingUrl: 'https://api.staging.montu.com.au/warehousing',
  shopifyStorefrontUrl: 'https://shop.leafio-stage.net/collections/all',
  launchDarklyConfig: {
    clientSideID: '64867d36ab4da812c04f2280',
  },
  shopifyRegistration: 'https://shop.leafio-stage.net/pages/registration',
  ...common
};

const qa = {
  url: 'https://leafio-qa-alb.crystaldelta.net/api/v1',
  warehousingUrl: 'https://api.staging.montu.com.au/warehousing',
  shopifyStorefrontUrl: 'https://shop.leafio-stage.net/collections/all',
  launchDarklyConfig: {
    clientSideID: '64867d36ab4da812c04f2280',
  },
  shopifyRegistration: 'https://shop.leafio-stage.net/pages/registration',
  ...common
};

const uat = {
  url: 'https://leafio-qa.crystaldelta.net/api/v1',
  warehousingUrl: 'https://api.staging.montu.com.au/warehousing',
  shopifyStorefrontUrl: 'https://shop.leafio-stage.net/collections/all',
  launchDarklyConfig: {
    clientSideID: '64867d36ab4da812c04f2280',
  },
  shopifyRegistration: 'https://shop.leafio-stage.net/pages/registration',
  ...common
};

const prod = {
  url: 'https://www.leafio.com.au/api/v1',
  warehousingUrl: 'https://api.montu.com.au/warehousing',
  shopifyStorefrontUrl: 'https://shop.leafio.com.au/collections/all',
  launchDarklyConfig: {
    clientSideID: '64867d2e9ed6ec122748e2e1',
  },
  shopifyRegistration: 'https://shop.leafio.com.au/pages/registration',
  ...common,
  GoogleAuthId: '178327000471-socap2som5r3lvafuk65t8t34dm37n1j.apps.googleusercontent.com',
  'recaptchaSiteKey': '6LfxpKcaAAAAAMsdrsz9rEZQbk90e4oR1n4y11fe',
};


let settings;
if (process.env.REACT_APP_APP_ENV === 'development') {
  settings = local;
}
else if (process.env.REACT_APP_APP_ENV === 'test') {
  settings = {
    ...local,
    launchDarklyConfig: null,
  };
}
else if (process.env.REACT_APP_APP_ENV === 'staging') {
  settings = staging;
}
else if (process.env.REACT_APP_APP_ENV === 'uat') {
  settings = uat;
}
else if (process.env.REACT_APP_APP_ENV === 'production') {
  settings = prod;
}
else if (process.env.REACT_APP_APP_ENV === 'qa') {
  settings = qa;
}

export default settings;
