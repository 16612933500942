import React from 'react';
import settings from '../../constants/constants';
import { Button } from '@mui/material';

const SHOPIFY_STOREFRONT_URL = settings.shopifyStorefrontUrl;

const PharmacyArchiveModeAlert = () => (
  <React.Fragment>
    <p>Your account has been migrated.</p>
    <p>Log in to the new Shopify Experience to place orders.</p>
    <p>
      <Button
        className='btn btn-secondary'
        href={SHOPIFY_STOREFRONT_URL}
        target='blank'
        rel='noopener noreferrer'
      >Go to Shopify</Button>
    </p>
  </React.Fragment>
);

export default PharmacyArchiveModeAlert;
