import { apm } from '@elastic/apm-rum';
import Moment from 'moment';
import qs from 'qs';
import {
  useMediaQuery
} from 'react-responsive';
import toasterNotification from '../components/common/toasterNotification';
import settings from '../constants/constants';
import { getUser, logout } from '../services/authentication.service';


// eslint-disable-next-line no-undefined
const checkValIsEmpty = value => value === undefined || value === null || value === '' || value === 'undefined';

const getDateInFormat = (date, format) => {
  let inputDate = Moment(date);
  if (date != null) {
    inputDate = Moment(date);
    return Moment(inputDate).format(format);
  }
  return '';
};

const truncateString = (val, file, charLength = 20) => {
  let result = '';
  if (!checkValIsEmpty(file) && !checkValIsEmpty(charLength)) {
    const split = file.split('.');
    const filename = split[0];
    const extension = split[1];
    if (filename.length > charLength) {
      result = filename.substring(0, charLength) + '.' + extension;
    } else
      result = filename + '.' + extension;
  } else if (!checkValIsEmpty(val) && !checkValIsEmpty(charLength)) {
    if (val.length > charLength) {
      result = val.substring(0, charLength) + '...';
    } else
      result = val;
  }
  return result;
};

const getUserName = () => {
  const userinfo = getUser();
  let username = userinfo.user.role_id === settings.role.admin ? 'Admin' : '';
  if (!checkValIsEmpty(userinfo.user.first_name) && !checkValIsEmpty(userinfo.user.last_name)) {
    username = userinfo.user.first_name + '' + userinfo.user.last_name;
  }
  return username;
};

const getFormatLabelVal = (val, prefix, defaultPlaceholder = ' - ') => {
  let formated = defaultPlaceholder;
  if (!checkValIsEmpty(val)) {
    formated = !checkValIsEmpty(prefix) ? prefix + val : val;
  }
  return formated;
};

const dynamicCardResponsive = () => {
  let cardViewCount = 1;
  const laptop = useMediaQuery({
    query: '(min-width: 1200px)'
  });
  const tablet = useMediaQuery({
    query: ' (min-width: 992px) and (max-width:1199px)'
  });
  if (tablet) {
    cardViewCount = 4;
  } else if (laptop) {
    cardViewCount = 5;
  }
  return cardViewCount;
};

const isMobileScreen = () => useMediaQuery({ maxWidth: settings.screenResolution.mobileMax });

const formatProductData =(arr, size) => {
  if (!Array.isArray(arr)) {
    throw new TypeError('Input should be Array');
  }
  if (typeof size !== 'number') {
    throw new TypeError('Size should be a Number');
  }
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, size + i));
  }
  return result;
};


const getdataTabledynamicHeight=(height, offsetY = 0)=>{
  let minHeight=500;
  if (!checkValIsEmpty(height) && !checkValIsEmpty(offsetY)) {
    minHeight = height - offsetY;
  }
  else minHeight = height;
  return minHeight;
};

const getRowStatusIndicator=(id)=> {
  const processingStatuses = [settings.orderItemStatus.AWAITING_APPROVAL,
    settings.orderItemStatus.AWAITING_SHIPMENT,
    settings.orderItemStatus.SHIPPED,
    settings.orderItemStatus.PENDING_APPROVAL,
    settings.orderItemStatus.WAREHOUSE_SHIPMENT,
    settings.orderItemStatus.AWAITING_APPROVAL_EXCEEDED_ORDER_LIMIT,
    settings.orderItemStatus.PROCESSING_IN_WAREHOUSE,
    settings.orderItemStatus.SUPPLIER_AWAITING_APPROVAL];
  const blockStatuses = [settings.orderItemStatus.APPROVAL_REJECTED,
    settings.orderItemStatus.APPROVAL_EXPIRED,
    settings.orderItemStatus.SHIPPED_WITH_DISPUTE,
    settings.orderItemStatus.CANCELLED,
    settings.orderItemStatus.DELETED,
    settings.orderItemStatus.REJECTED_BY_SUPPLIER];
  let colorCode='#DBDBDB';
  if (!checkValIsEmpty(id)){
    if (processingStatuses.includes(id)){
      colorCode='#FFB52B';
    }
    else if (blockStatuses.includes(id)){
      {
        colorCode='#F13426';
      }
    }
    else if (settings.orderItemStatus.COMPLETED === id){
      {
        colorCode='#00C455';
      }
    }
  }
  return colorCode;
};

const orderItemActionDisableByStatus = (id,actionType) => {
  let disable =false;
  switch (actionType) {
    case 'checkbox':
      disable = [
        settings.orderItemStatus.AWAITING_APPROVAL,
        settings.orderItemStatus.APPROVAL_REJECTED,
        settings.orderItemStatus.APPROVAL_EXPIRED,
        settings.orderItemStatus.SHIPPED_WITH_DISPUTE,
        settings.orderItemStatus.CANCELLED,
        settings.orderItemStatus.SHIPPED,
        settings.orderItemStatus.PENDING_APPROVAL,
        settings.orderItemStatus.WAREHOUSE_SHIPMENT,
        settings.orderItemStatus.COMPLETED,
        settings.orderItemStatus.AWAITING_APPROVAL_EXCEEDED_ORDER_LIMIT,
        settings.orderItemStatus.PROCESSING_IN_WAREHOUSE,
        settings.orderItemStatus.DELETED,
        settings.orderItemStatus.SUPPLIER_AWAITING_APPROVAL,
        settings.orderItemStatus.REJECTED_BY_SUPPLIER,
      ].includes(id);
      break;
    case 'upload':
      disable=[
        settings.orderItemStatus.APPROVAL_REJECTED,
        settings.orderItemStatus.APPROVAL_EXPIRED,
        settings.orderItemStatus.PENDING_APPROVAL,
      ].includes(id);
      break;
    case 'edit':
      disable=[
        settings.orderItemStatus.AWAITING_APPROVAL,
        settings.orderItemStatus.AWAITING_SHIPMENT,
        settings.orderItemStatus.APPROVAL_REJECTED,
        settings.orderItemStatus.PENDING_APPROVAL,
        settings.orderItemStatus.APPROVAL_EXPIRED,
        settings.orderItemStatus.AWAITING_APPROVAL_EXCEEDED_ORDER_LIMIT,
        settings.orderItemStatus.SUPPLIER_AWAITING_APPROVAL,
        settings.orderItemStatus.REJECTED_BY_SUPPLIER,
      ].includes(id);
      break;
  }
  return disable;
};

const convertUTCToLocalDate=(date)=>{
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
};

const convertLocalToUTCDate=(date)=> {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return date;
};

const getSupplierOrderRowStatusIndicator = (approvalStatusId) => {
  let colorCode='#00C455';
  if (!checkValIsEmpty(approvalStatusId)){
    if (approvalStatusId === settings.orderItemStatus.SUPPLIER_AWAITING_APPROVAL){
      colorCode = '#FFB52B';
    }
    else if (approvalStatusId === settings.orderItemStatus.REJECTED_BY_SUPPLIER){
      colorCode = '#F13426';
    }
  }
  return colorCode;
};

const getSupplierRowStyle = (statusId) => {
  const style = { borderLeft: `4px solid ${getSupplierOrderRowStatusIndicator(statusId)}` };
  let approvedStyle = {};
  if (statusId !== settings.orderItemStatus.SUPPLIER_AWAITING_APPROVAL) {
    approvedStyle = { backgroundColor: 'rgba(235, 235, 228, 0.5)', color: '#808080' };
  }
  return { ...style, ...approvedStyle };
};

const doesStringHaveSpecialChars = (value) => {
  const specialCharRegex = new RegExp('^[a-zA-Z0-9]+$');
  return !specialCharRegex.test(value);
};

const filterQueryParamsNonNull = (params) => {
  return Object.fromEntries(Object.entries(params).filter(([, val]) => val));
};

const buildUrlWithQuery = (baseUrl, params = { page: 1 }, withPageQuery = true) => {
  const { pagination: { limit } } = settings;
  const { page, ...restParams } = params;

  const queryParams = filterQueryParamsNonNull(restParams);

  if (queryParams.order_status === 0) {
    delete queryParams.order_status;
  }

  if (withPageQuery) {
    const offset = (page - 1) * limit;
    queryParams.limit = limit;
    queryParams.offset = !isNaN(offset) ? offset : '0';
  } else {
    delete queryParams.page;
    delete queryParams.limit;
  }

  const queryString = qs.stringify(queryParams);

  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
};

const handleAPMError = (error, toaster = null) => {
  if (error.response && error.response.status === settings.unauthorized) {
    void logout(true);
    toasterNotification('Please login to continue', 'error', { id: 'unauthorized-error' });
    return;
  }
  if (toaster && toaster.id && toaster.message) {
    toasterNotification(toaster.message, 'error', { id: toaster.id });
  }
  apm.captureError(error);
};

export {
  checkValIsEmpty,
  getDateInFormat,
  truncateString,
  getUserName,
  getFormatLabelVal,
  dynamicCardResponsive,
  formatProductData,
  isMobileScreen,
  getdataTabledynamicHeight,
  getRowStatusIndicator,
  orderItemActionDisableByStatus,
  convertUTCToLocalDate,
  convertLocalToUTCDate,
  getSupplierRowStyle,
  doesStringHaveSpecialChars,
  buildUrlWithQuery,
  handleAPMError
};
