import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import {
  FF_SUPPLIER_SHOPIFY_ORDERS,
  FF_APHRA_MAX_UPLOAD_SIZE_MB,
  FF_APPROVAL_MAX_FILE_SIZE_MB,
  FF_PHARMACIST_NEW_EXPERIENCE_CHECKOUT,
  FF_PHARMACIST_NEW_EXPERIENCE_ORDERS,
  FF_PHARMACIST_NEW_EXPERIENCE,
  FF_SHIPMENTS_EXPORT,
  FF_USE_CART_LOADER,
  FF_PHARMACIST_ARCHIVE_MODE,
  FF_BULK_CART_MAX_UPLOAD_SIZE_MB,
  FF_PHARMACY_REGISTRATION
} from '../constants/featureFlags';

const ffDefaults: LDFlagSet = {
  [FF_SUPPLIER_SHOPIFY_ORDERS]: true,
  [FF_APHRA_MAX_UPLOAD_SIZE_MB]: 5,
  [FF_APPROVAL_MAX_FILE_SIZE_MB]: 5,
  [FF_PHARMACIST_NEW_EXPERIENCE_CHECKOUT]: true,
  [FF_PHARMACIST_NEW_EXPERIENCE_ORDERS]: true,
  [FF_PHARMACIST_NEW_EXPERIENCE]: true,
  [FF_SHIPMENTS_EXPORT]: false,
  [FF_USE_CART_LOADER]: true,
  [FF_PHARMACIST_ARCHIVE_MODE]: true,
  [FF_BULK_CART_MAX_UPLOAD_SIZE_MB]: 0,
  [FF_PHARMACY_REGISTRATION]: true,
};

export { ffDefaults };
