import { init as initApm } from '@elastic/apm-rum';

initApm({

  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'montu-leafio-frontend',

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: 'https://5c460b35aaaa424491760e12614fccc2.apm.ap-southeast-2.aws.cloud.es.io/',

  // eslint-disable-next-line no-undef
  environment: process.env.REACT_APP_APP_ENV,
  // Set service version (required for sourcemap feature)
  serviceVersion: ''
});
